import React, { useEffect, useState } from 'react'
import ClubBanner from '../components/ClubBanner/ClubBanner'
import ClubSubscription from '../components/ClubSubscription/ClubSubscription'
import Footer from '../components/Footer/Footer'
import KitPreview from '../components/KitPreview/KitPreview'
import Newsletter from '../components/Newsletter/Newsletter'
import NextKit from '../components/NextKit/NextKit'
import PreviousKits from '../components/PreviousKits/PreviousKits'
import TAGTopics from '../components/TAGTopics/TAGTopics'
import { ThemeProvider } from 'styled-components'
import VideoModal from '../components/VideoModal/VideoModal'
import { clubBannerCuradoriaData } from '../components/ClubBanner/ClubBannerData'
import { clubCuradoriaData } from '../components/ClubSubscription/ClubSubscriptionData'
import { curadoriaTopicsData } from '../components/TAGTopics/TAGTopicsData'
import { graphql } from 'gatsby'
import { kitPreviewCuradoriaData } from '../components/KitPreview/KitPreviewData'
import { nextCuradoriaData } from '../components/NextKit/NextKitData'
import useChatbot from '../helpers/useChatbot'
import HowItWorks from '@site/components/organisms/HowItWorks'
import CookiesFab from '@site/components/organisms/CookiesFab'
import Experience from '@site/components/organisms/Experience'
import CommonHead from '@shared/meta/CommonHead'
import useSubscriptionPlans from '@site/hooks/useSubscriptionPlans'

export function Head() {
  return (
    <CommonHead
      title="TAG Curadoria | Clube de Assinatura de Livros"
      description="Torne-se um associado e escolha sua caixinha preferida para receber, a cada mês, um livro surpresa. Conheça nossas opções e viva essa experiência!"
    />
  )
}

const curadoriaTheme = {
  'bannerBackgroundColor': '#e6ccbd',
  'clubMainColor': '#009bbf',
  'clubSecondaryColor': '#00c2e2',
  'howItWorksSubtitleLineHeight': '1.8',
  'howItWorksTitleMaxWidth': '250px',
  'largeSelector': true,
  'planSelectorBackgroundColor': '#16b9df',
  'planSelectorUncheckedButtonColor': '#96a0c0',
  'topicsBackgroundColor': '#141e3e',
  'topicsNumberColor': '#4b64b1',
}

const Curadoria = ( { data } ) => {
  const [ videoModalOpen, setVideoModalOpen ] = useState( false )
  const [ isPageRendered, setIsPageRendered ] = useState( false )
  const { 'allStrapiCuradoria': { edges } } = data
  const [ strapiData ] = edges
  const kitPreviewImages = {
    'desktopImage': strapiData.node.KitPreviewImage.desktopImage?.localFile?.publicURL,
    'mobileImage': strapiData.node.KitPreviewImage.mobileImage?.localFile?.childImageSharp?.gatsbyImageData.images
  }
  const bannerSubtitle = strapiData.node.banner.subtitle
  const bannerActiveClock = strapiData.node.banner.activeClock
  const kitPreviewData = { ...kitPreviewImages, ...kitPreviewCuradoriaData }
  const nextKitData = { ...strapiData.node.NextKit, ...nextCuradoriaData }
  const bannerData = { ...clubBannerCuradoriaData, 'subtitle': bannerSubtitle, 'activeClock': bannerActiveClock }

  const { 'allStrapiGifts': { nodes } } = data
  const curadoriaGift = nodes[ 0 ].curadoria

  useChatbot()
  const subscriptionPlans = useSubscriptionPlans()

  useEffect( () => {
    setIsPageRendered( true )
  }, [] )

  return (
    <>
      {( videoModalOpen &&
        <VideoModal
          onClose={ () => {
            setVideoModalOpen( false )
          } }
          source={ `${process.env.GATSBY_ASSETS_URL}/video_curadoria.mp4` }
        /> )}
      <ThemeProvider theme={ curadoriaTheme }>
        <ClubBanner
          playButtonOnClick={ () => {
            setVideoModalOpen( true )
          } }
          { ...bannerData }
        />
        <HowItWorks
          page="curadoria"
          subscriptionPlans={ subscriptionPlans }
        />
        <KitPreview { ...kitPreviewData } />
        <PreviousKits club="curadoria" />
        <NextKit { ...nextKitData } />
        <TAGTopics { ...curadoriaTopicsData } />
        <ClubSubscription { ...clubCuradoriaData( subscriptionPlans, curadoriaGift ) } />
        { isPageRendered && <Experience /> }
        <Newsletter />
        <Footer />
        <CookiesFab />
      </ThemeProvider>
    </>
  )
}

export default Curadoria

export const query = graphql` {
  allStrapiCuradoria(limit: 1, sort: {order: DESC, fields: strapiId}) {
    edges {
      node {
        banner {
          subtitle
          activeClock
        }
        HowItWorks {
          column {
            id
            subtitle
            title
          }
          title
        }
        KitPreviewImage {
          desktopImage {
            localFile {
              publicURL
            }
          }
          mobileImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        NextKit {
          title
          description
          items {
            id
            subtitle
            icon {
              url
              alternativeText
            }
          }
          buttonText
          buttonLink
        }
      }
    }
  }
  allStrapiGifts(limit: 1, sort: {order: DESC, fields: strapiId}) {
    nodes {
      curadoria {
        title
        text
        thumbnailImage {
          localFile {
            childImageSharp {
              gatsbyImageData (
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        zoomImage {
          localFile {
            childImageSharp {
              gatsbyImageData (
                width: 1500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        description
      }
    }
  }
}
`
